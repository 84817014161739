@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Poppins'; /* Name your font family */
  src: url('/public/fonts/Poppins/Poppins-Medium.ttf') format('truetype'); /* Correct relative path */
}

@font-face {
  font-family: 'Cairo'; /* Name your font family */
  src: url('/public/fonts/Cairo/static/Cairo-Medium.ttf') format('truetype'); /* Correct relative path */
  font-weight: 600;
}





body {
  background-color: #F0F4F8;
}

.header {
  background-image: linear-gradient(to right, white 0%, #C5DEEB 99%) !important;
}

.header2 {
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0.2rem;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.mehrButton{
  font-family: 'Poppins', sans-serif;
  font-weight: medium;
}

.headerSingles {
  color: #00587A;
  transition: color 0.3s ease;
  font-family: 'Poppins', sans-serif;
  font-weight: medium;
}

.headerSingles:hover {
  color: #0099D6;
}

.headerSingles:not(:hover) {
  transition: color 0.3s ease 0.5s;
}

.schriftaufBild {
  font-family: 'Cairo', sans-serif;
  color: #00587A;
}

.card {
  background-color: #FCFCFD;
  width: 80%;
  padding: 2rem;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
}

.card h2 {
  font-family: 'Cairo', sans-serif;
  font-weight: 600;
  color: #00587A;
  white-space: nowrap;
}

.card h2 span {
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 0;
}

.card h2 span::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
  background-image: linear-gradient(to right, rgba(197, 222, 235, 0.8) 75%, rgba(197, 222, 235, 0.1));
  z-index: -1;
}

.card p {
  font-family: 'Poppins', sans-serif;
  color: #333333;
}

.card strong {
  font-weight: 500;
}

@media only screen and (max-width: 767px) {   /* 767 is the breakpoint to md */   
  .card {
    background-color: #FCFCFD;
    width: 100%;
    padding: 2rem;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
  }

  .card h2 {
    font-family: 'Cairo', sans-serif;
    font-weight: 600;
    color: #00587A;
    white-space: nowrap;
  }

  .card h2 span {
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 0;
  }

  .card h2 span::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(to right, rgba(197, 222, 235, 0.8) 75%, rgba(197, 222, 235, 0.1));
    z-index: -1;
  }

  .card p {
    font-family: 'Poppins', sans-serif;
    color: #333333;
    font-size: small;
  }

  .card strong {
    font-weight: 500;
    font-size: small;
  }

  .card li {
    
    font-size: small;
  }

  .card a {
    font-size: small;
  }
}
.footerFontB {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1rem;
}
.footerFontM {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 0.7rem;
}
.footerFontS {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-size: 7px;
}



.startIMG {
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
}

svg:hover {
  fill: #0099D6;
  transition: fill 0.3s ease;
}

svg:hover {
  fill: #ffffff;
  transition: fill 0.3s ease;
}




.burger-menu {
  height: 100%;
  width: 4em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
}

.burger-bar {
  width: 2em;
  height: 0.2em;
  background-color: rgb(0, 183, 255);
  border-radius: 0.5em;
  margin-top: 5px;
}

.menu {
  width: 20%;
  height: 80vh;
  position: absolute;
  top: 0;
}

.menu2 {
  background-color: #fff;
  border-bottom: 1px solid #8ea7c5;
}

.menu2element {
  padding: 0.4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}


/* ----- animations--------- */
/* clicked */
.burger-bar.clicked:nth-child(1) {
  transform: rotate(45deg) translate(0em, 0.3em);
  transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(2) {
  transform: scale(0.1);
  transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(3) {
  transform: rotate(135deg) translate(-0.2em, 0.7em);
  transition: ease-out 0.5s;
}

/* unclicked */
.burger-bar.unclicked {
  transform: rotate(0) translate(0);
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}

.shine-animation {
  animation: shine 5s ease-in-out infinite;
}

@keyframes shine {
  0% {
      transform: translateX(-100%) skewX(-45deg);
  }
  100% {
      transform: translateX(100%) skewX(45deg);
  }
}